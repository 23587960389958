import React from "react"
import { graphql } from 'gatsby'
import { Grid, Margin, PageWrapper } from '@components/atoms'
import { GridItem, HomeProjectCenter } from '@components/molecules'
import { Newsletter, TransitionMask } from '@components/organisms'
import { PageProps } from '@types'


const IndexPage = ({data: { 
  sanityPage: {
    gridItems,
    extraHomeFields,
  },
}}: { data: PageProps }) => {
  const topProjects = extraHomeFields?.posts.filter(post => !!post).filter((_, i) => i < 6)
  const botProjects = extraHomeFields?.posts.filter(post => !!post).filter((_, i) => i >= 6)
  return (
    <PageWrapper>
      <Margin>
        <Grid>
          {gridItems.map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
          {topProjects?.map((item, i) => (
            <HomeProjectCenter {...item} key={`project_0_${i}`} />
          ))}
          <Newsletter />
          {botProjects?.map((item, i) => (
            <HomeProjectCenter {...item} key={`project_1_${i}`} />
          ))}
        </Grid>
      </Margin>
      <TransitionMask />
    </PageWrapper>
  )
}


/*
const LearnWrapper = styled.div`
  grid-column: 1 / span 6;
  text-align: center;
  a {
    display: inline-block;
    background-color: black;
    color: #ff0000;
    text-decoration: none;
    border-radius: 9999px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 1em;
    padding: 12px 25px 10px 25px;
  }
  @media only screen and (min-width: 744px) {
    display: none;
  }
`
*/


export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: {current: {eq: "home"}}) {
      ...pageFields
      extraHomeFields {
        posts {
          title
          slug {
            current
          }
          description
          tagLine
          shareImage {
            asset {
              url
              metadata {
                dimensions {
                  width
                  height
                  aspectRatio
                }
              }
            }
          }        
        }
      }
    }
  }
`

export default IndexPage
